import Vue from "vue";
// import VueRouter from "vue-router";
import Router from "vue-router";

// 首页
import HomeView from "@/views/HomeView";

// 第一个
import kaiYuans from "@/views/kaiYuans/kaiYuans";

// 小井峪古戏台  3;
import guXitai from "@/views/guXitai/guXitai";

// 古槐 第五个
import guHuai from "@/views/guHuai/guHuai";

// 古井 第六个
import guJing from "@/views/guJing/guJing";

// 村使馆 第七个
import cuShiGuan from "@/views/cuShiGuan/cuShiGuan";

//  亲子园  12
import qinZiYuan from "@/views/qinZiYuan/qinZiYuan";

// 小井峪剧场  15
import xiaoJingYUJuChang from "@/views/xiaoJingYUJuChang/xiaoJingYUJuChang";

//  文玩街  17
import wenwanjie from "@/views/wenWanJie/wenWanJie";

// 解决导航重复
const originalPush = Router.prototype.push;
Router.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};

Vue.use(Router);

export default new Router({
  routes: [
    {
      path: "/",
      name: "首页",
      component: HomeView,
      hidden: true,
    },
    // 第一个
    {
      path: "/kaiYuans/kaiYuans",
      name: "kaiYuans",
      component: kaiYuans,
    },
    // 小井峪古戏台
    {
      path: "/guXitai/guXitai",
      name: "guXitai",
      component: guXitai,
    },
    // 古槐 第五个
    {
      path: "/guHuai/guHuai",
      name: "guHuai",
      component: guHuai,
    },
    // 古井 第六个
    {
      path: "/guJing/guJing",
      name: "guJing",
      component: guJing,
    },
    // 村使馆 第七个
    {
      path: "/cuShiGuan/cuShiGuan",
      name: "cuShiGuan",
      component: cuShiGuan,
    },
    // 亲子园  12
    {
      path: "/qinZiYuan/qinZiYuan",
      name: "qinZiYuan",
      component: qinZiYuan,
    },
    // 小井峪剧场  15
    {
      path: "/xiaoJingYUJuChang/xiaoJingYUJuChang",
      name: "xiaoJingYUJuChang",
      component: xiaoJingYUJuChang,
    },
    // 文玩街  17
    {
      path: "/wenwanjie/wenwanjie",
      name: "wenwanjie",
      component: wenwanjie,
    },
  ],
});

<template>
  <div class="home">
    <div class="home1">小井峪详情</div>
    <div class="header">
      <img src="@/assets/xjyjc.jpg" alt="">
    </div>
    <div class="tit">
      <div class="tit1">详情简介：</div>
      <div class="tit2">{{textToSpeak}}</div>
    </div>
    <button ref="clickMe" @click="speakText" style="display:none"></button>
  </div>
</template>

<script>
export default {
  data() {
    return {
      textToSpeak:
        "小井峪剧场位于井谷里文化公园东南侧，始建于 1962 年，2004 年重新翻建剧场，剧场上题魏碑书法“小井峪剧场”，两侧对联内是“轻歌曼舞讴盛世，铜琶铁板震神州”。自建成之后，剧场在每年农历七月二十五的小井峪庙会上都要连唱四至五天的晋剧大戏。  ",
    };
  },
  mounted() {
    this.$refs.clickMe.click();
    // 在组件创建时设置TTS监听路由变化
    this.unregisterHook = this.$router.afterEach((to, from) => {
      if (to !== from) {
        this.stopTTS();
      }
    });
  },

  methods: {
    // 播放
    speakText() {
      if ("speechSynthesis" in window) {
        const msg = new SpeechSynthesisUtterance(this.textToSpeak);
        // 设置语音合成的参数
        msg.lang = "zh-CN"; // 设置语言
        msg.rate = 1; // 设置语速
        msg.pitch = 1; // 设置音调
        msg.volume = 1; // 设置音量
        // 开始播放
        speechSynthesis.speak(msg);
        // 监听结束事件
        msg.onend = () => {};
      }
    },
    stopTTS() {
      speechSynthesis.cancel();
    },
  },
};
</script>


<style scoped>
.home {
  height: 100vh;
}

.home1 {
  height: 50px;
  text-align: center;
  font-weight: 700;
  line-height: 3;
}

.header {
  width: 96%;
  height: 32vh;
  box-sizing: border-box;
  margin: 0 auto;
  border-radius: 50%;
}

.header > img {
  width: 100%;
  height: 100%; /* 高度自动调整 */
  border-radius: 8px;
}
.tit {
  width: 95%;
  margin: 20px auto;
}

.tit1 {
  font-weight: 700;
  letter-spacing: 3px;
}

.tit2 {
  margin-top: 4%;
  line-height: 2.5;
  letter-spacing: 3px;
  text-indent: 2em;
}
</style>

<template>
  <div class="home">
    亲子园 12
  </div>

</template>

<script>
export default {
  data() {
    return {};
  },

  methods: {},
};
</script>


<style scoped>
</style>

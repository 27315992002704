<template>
  <div class="home">
    <div class="home1">文玩街详情</div>
    <div class="header">
      <img src="@/assets/wwj.jpg" alt="">
    </div>
    <div class="tit">
      <div class="tit1">详情简介：</div>
      <div class="tit2">{{textToSpeak}}</div>
    </div>
    <button ref="clickMe" @click="speakText" style="display:none"></button>
  </div>
</template>

<script>
export default {
  data() {
    return {
      textToSpeak:
        "文玩步行街位于井谷里文化公园以南，长 270 余米，宽 20 余米，沿街设有数十间文玩商铺，造型古色古香，文化氛围浓厚。文玩商铺售卖铜钱、银币、银元、纸币、元宝、纪念币等钱币；翡翠、手镯、手串、珠宝、金饰等饰品；名人字画、书画、宣纸、文房四宝等书画工艺品；老酒、老烟、古线、老药、旧书等老物件；太行崖柏、太行奇石等微景观；瓷器、木器、铜器、玉器、石器等器物；邮票等邮品；家具；礼册、太岁等杂项。 ",
    };
  },
  mounted() {
    this.$refs.clickMe.click();
    // 在组件创建时设置TTS监听路由变化
    this.unregisterHook = this.$router.afterEach((to, from) => {
      if (to !== from) {
        this.stopTTS();
      }
    });
  },

  methods: {
    // 播放
    speakText() {
      if ("speechSynthesis" in window) {
        const msg = new SpeechSynthesisUtterance(this.textToSpeak);
        // 设置语音合成的参数
        msg.lang = "zh-CN"; // 设置语言
        msg.rate = 1; // 设置语速
        msg.pitch = 1; // 设置音调
        msg.volume = 1; // 设置音量
        // 开始播放
        speechSynthesis.speak(msg);
        // 监听结束事件
        msg.onend = () => {};
      }
    },
    stopTTS() {
      speechSynthesis.cancel();
    },
  },
};
</script>


<style scoped>
.home {
  height: 100vh;
}

.home1 {
  height: 50px;
  text-align: center;
  font-weight: 700;
  line-height: 3;
}

.header {
  width: 96%;
  height: 32vh;
  box-sizing: border-box;
  margin: 0 auto;
  border-radius: 50%;
}

.header > img {
  width: 100%;
  height: 100%; /* 高度自动调整 */
  border-radius: 8px;
}
.tit {
  width: 95%;
  margin: 20px auto;
}

.tit1 {
  font-weight: 700;
  letter-spacing: 3px;
}

.tit2 {
  margin-top: 4%;
  line-height: 2.5;
  letter-spacing: 3px;
  text-indent: 2em;
}
</style>

<template>
  <div class="home">
    <img src="../assets/logo.png" class="zoomed-image">
    <div class="content">
      <!-- 第一个 -->
      <div class="one" @click="kaiyuansi"></div>
      <!-- 第二个  -->
      <div class="two" @click="tinglang"></div>
      <!-- 小井峪古戏台  3 -->
      <div class="three" @click="guxitai"></div>
      <!-- 第四个 -->
      <div class="four" @click="jingyuge"></div>
      <!-- 古槐 第五个 -->
      <div class="five" @click="guhuai"></div>
      <!-- 古井 第六个 -->
      <div class="six" @click="gujing"></div>
      <!--村使馆 第七个 -->
      <div class="seven" @click="gushiguan"></div>
      <!-- 第八个 -->
      <div class="eight" @click="zhanlanguan"></div>
      <!-- 第九个 -->
      <div class="nine" @click="wenguagongyuan"></div>
      <!-- 第十个 -->
      <div class="ten" @click="wenguachanglang"></div>
      <!-- 亲子园  12 -->
      <div class="twelve" @click="qinziyuan"></div>
      <!-- 小井峪剧场  15 -->
      <div class="fifteen" @click="sxsjc"></div>
      <!-- 文玩街  17 -->
      <div class="seventeen" @click="wenwanjie"></div>

    </div>
    <button ref="clickMe" @click="speakText" style="display:none">Speak</button>
  </div>

</template>
<script>
export default {
  data() {
    return {
      textToSpeak:
        "各位游客朋友，欢迎大家来小井峪这个有着1360多年历史的村庄。这里留存乡土文化，承载情感记忆，用传统和现代绘就今天的美丽。",
    };
  },
  mounted() {
    this.$refs.clickMe.click();
    // 在组件创建时设置TTS监听路由变化
    this.unregisterHook = this.$router.afterEach((to, from) => {
      if (to !== from) {
        this.stopTTS();
      }
    });
  },
  methods: {
    // // 第一个
    kaiyuansi() {
      this.$router.push({ path: "/kaiYuans/kaiYuans" });
    },
    // // 第二个
    tinglang() {},
    // 小井峪古戏台  3
    guxitai() {
      this.$router.push({ path: "/guXitai/guXitai" });
    },
    //  第四个
    jingyuge() {},
    //  古槐 第五个
    guhuai() {
      this.$router.push({ path: "/guHuai/guHuai" });
    },
    // 古井 第六个
    gujing() {
      this.$router.push({ path: "/guJing/guJing" });
    },
    // -村使馆 第七个
    gushiguan() {
      this.$router.push({ path: "/cuShiGuan/cuShiGuan" });
    },
    // 第八个
    zhanlanguan() {},
    // 第九个
    wenguagongyuan() {},
    // 第十个
    wenguachanglang() {},
    // 亲子园 12
    qinziyuan() {
      this.$router.push({ path: "/qinZiYuan/qinZiYuan" });
    },
    // 小井峪剧场 15
    sxsjc() {
      this.$router.push({ path: "/xiaoJingYUJuChang/xiaoJingYUJuChang" });
    },
    // 文玩街  17
    wenwanjie() {
      this.$router.push({ path: "/wenwanjie/wenwanjie" });
    },
    // 播放
    speakText() {
      if ("speechSynthesis" in window) {
        const msg = new SpeechSynthesisUtterance(this.textToSpeak);
        // 设置语音合成的参数
        msg.lang = "zh-CN"; // 设置语言
        msg.rate = 1; // 设置语速
        msg.pitch = 1; // 设置音调
        msg.volume = 1; // 设置音量
        // 开始播放
        speechSynthesis.speak(msg);
        // 监听结束事件
        msg.onend = () => {};
      }
    },
    stopTTS() {
      window.speechSynthesis.cancel();
    },
  },
};
</script>


<style scoped>
.home {
  width: 100%;
  height: 100vh;
  position: relative;
  left: 0;
  z-index: 9999;
  box-sizing: border-box;
}

.home > img {
  width: 100%;
  height: 100%; /* 高度自动调整 */
}

.content {
  width: 74%;
  height: 44%;
  position: absolute;
  left: 14%;
  top: 39%;
  right: 0;
  bottom: 0;
  /* border: 1px solid; */
}

/* 第一个  */
.one {
  width: 8%;
  height: 6%;
  /* border: 1px solid; */
  box-sizing: border-box;
  margin-left: 25px;
  margin-top: 16px;
}
/* 第二个   */
.two {
  width: 8%;
  height: 5%;
  /* border: 1px solid; */
  box-sizing: border-box;
  margin-left: 47%;
  margin-top: -18px;
}

/* 小井峪古戏台  3 */
.three {
  width: 8%;
  height: 6%;
  border: 1px solid;
  box-sizing: border-box;
  margin-left: 64%;
  margin-top: 4px;
}

/* 第四个 */
.four {
  width: 7%;
  height: 5%;
  /* border: 1px solid; */
  box-sizing: border-box;
  margin-left: 36%;
  margin-top: -9%;
}

/* 古槐 第五个  */
.five {
  width: 8%;
  height: 6%;
  border: 1px solid;
  box-sizing: border-box;
  margin-left: 42%;
  margin-top: -19px;
}

/* 古井 第六个  */
.six {
  width: 3%;
  height: 3%;
  border: 1px solid;
  box-sizing: border-box;
  margin-left: 57%;
  margin-top: -6px;
}

/* 村使馆 第七个 */
.seven {
  width: 5%;
  height: 5%;
  border: 1px solid;
  box-sizing: border-box;
  margin-left: 54%;
}

/* 第八个 */
.eight {
  width: 4%;
  height: 4%;
  /* border: 1px solid; */
  box-sizing: border-box;
  margin-left: 32%;
  margin-top: -15px;
}
/* 第九个 */
.nine {
  width: 4%;
  height: 4%;
  /* border: 1px solid; */
  box-sizing: border-box;
  margin-left: 35%;
  margin-top: 0px;
}
/* 第十个 */
.ten {
  width: 5%;
  height: 9%;
  /* border: 1px solid; */
  box-sizing: border-box;
  margin-left: 31%;
  margin-top: -13px;
}

/* 亲子园  12 */
.twelve {
  width: 9%;
  height: 8%;
  border: 1px solid;
  box-sizing: border-box;
  margin-left: 37%;
  margin-top: -4%;
}

/* 小井峪剧场 15 */
.fifteen {
  width: 9%;
  height: 8%;
  border: 1px solid;
  box-sizing: border-box;
  margin-left: 60%;
  margin-top: 17px;
}

/* 文玩街  17 */
.seventeen {
  height: 38%;
  border: 1px solid;
  box-sizing: border-box;
  margin-left: 32%;
  margin-top: 8%;
}
</style>

import { render, staticRenderFns } from "./guJing.vue?vue&type=template&id=e118dc28&scoped=true"
import script from "./guJing.vue?vue&type=script&lang=js"
export * from "./guJing.vue?vue&type=script&lang=js"
import style0 from "./guJing.vue?vue&type=style&index=0&id=e118dc28&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e118dc28",
  null
  
)

export default component.exports